import React, { useEffect, useRef, useState } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	Input,
	ModalFooter,
	Button,
	ModalContent,
	Box,
	PinInput,
	PinInputField,
	HStack,
} from '@chakra-ui/react';

import { firebase } from '../api';

const SignInModal = ({
	onClose,
	isOpen,
}: {
	onClose: () => void;
	isOpen: boolean;
}) => {
	const [number, setNumber] = useState('');
	const [showOtp, setShowOtp] = useState(false);
	const [confirmationResult, setConfirmationResult] = useState(null);

	const onOtpComplete = async (val: string) => {
		if (confirmationResult) {
			if (val === null) return;

			try {
				const { user } = await (confirmationResult as any).confirm(val);
				onClose();
			} catch (error) {
				console.error(error);
			}
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent mx='1em'>
				<ModalHeader>Create your account</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<FormControl>
						<FormLabel>Телефонски број</FormLabel>
						<Input
							placeholder='+389 70 123 456'
							onChange={(e) => setNumber(e.target.value as any)}
							value={number}
						/>
					</FormControl>

					{showOtp && (
						<FormControl mt={4}>
							<FormLabel>Пинот од СМС порака</FormLabel>
							<HStack gridColumnGap={2}>
								<PinInput otp onComplete={onOtpComplete}>
									<PinInputField />
									<PinInputField />
									<PinInputField />
									<PinInputField />
									<PinInputField />
									<PinInputField />
								</PinInput>
							</HStack>
						</FormControl>
					)}
				</ModalBody>

				<Box id='recaptcha-container' w='max-content' mx='auto' />

				<ModalFooter>
					<Button
						colorScheme='messenger'
						mr={3}
						onClick={async () => {
							const recaptcha = new firebase.auth.RecaptchaVerifier(
								'recaptcha-container'
							);

							try {
								const e = await firebase
									.auth()
									.signInWithPhoneNumber(number as any, recaptcha);

								setShowOtp(true);

								setConfirmationResult(e as any);
							} catch (error) {
								console.error(error);
							}
						}}
					>
						Продолжи
					</Button>
					<Button onClick={onClose}>Исклучи</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default SignInModal;
