import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
	apiKey: 'AIzaSyCuxQ-1C5iw1YZVxtvr4l4hjfCQqOZUimg',
	authDomain: 'easter-fest.firebaseapp.com',
	projectId: 'easter-fest',
	storageBucket: 'easter-fest.appspot.com',
	messagingSenderId: '305674362883',
	appId: '1:305674362883:web:baacc08a9de258f31e2e36',
	measurementId: 'G-PPB8ZQV629',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const auth = firebase.auth();
export const storage = firebase.storage();
export const db = firebase.firestore();
export { firebase };
