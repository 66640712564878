import React, { useState, useRef, useEffect } from 'react';
import {
	FormControl,
	FormLabel,
	Input,
	Textarea,
	Stack,
	Select,
	Button,
	Text,
	FormHelperText,
} from '@chakra-ui/react';
import { AttachmentIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { nanoid } from 'nanoid';

import { storage, db, firebase } from '../api';

const New: React.FC = () => {
	const [values, setValues] = useState({
		class: null,
		title: '',
		desc: '',
		students: '',
		password: '',
	});

	const [image, setImage] = useState(null);

	const imageInput = useRef(null);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleSubmit = async () => {
		const { password, ...vals } = values;

		if (!image || password !== 'MirceVeligden21_420') return;

		setIsSubmitting(true);

		const imageId = nanoid();

		const storageRef = storage.ref();
		const imageRef = storageRef.child(`images/${imageId}.jpg`);

		const imageBlob = new Blob([image as any]);

		const snapshot = await imageRef.put(imageBlob);
		console.log('Uploaded a blob or file!');

		const imageOptions = {
			...vals,
			imageUrl: await imageRef.getDownloadURL(),
			students: vals.students.split(/\, */),
			date: firebase.firestore.Timestamp.now(),
			likes: 0,
		};

		await db.collection('posts').doc(imageId).set(imageOptions);

		console.log('Set document to', imageOptions);

		window.location.href = '/';
	};

	const onChange = ({
		target,
	}: React.ChangeEvent<
		HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
	>) => setValues({ ...values, [target.name]: target.value });

	return (
		<Stack w='clamp(32.5ch, 50ch, 100%)' pt={3} pb={10} px={7} gridRowGap={3}>
			<FormControl isRequired>
				<FormLabel>Ученици</FormLabel>
				<Input
					placeholder='Влатко Стојкоски'
					name='students'
					onChange={onChange}
				/>
				<FormHelperText>
					За да внесете повеќе ученици напишете ги одделени со запирка. Пример
					"Име1 Презиме1, Име2 Презиме2"
				</FormHelperText>
			</FormControl>

			<FormControl isRequired>
				<FormLabel>Одделение</FormLabel>
				<Select
					placeholder='Изберете одделение...'
					name='class'
					onChange={onChange}
				>
					{Array.from({ length: 9 * 4 }, (v, i) => (
						<option key={i}>
							{Math.floor(i / 4) + 1}
							{['а', 'б', 'в', 'г'][i % 4]}
						</option>
					))}
				</Select>
			</FormControl>

			<FormControl isRequired>
				<FormLabel>Наслов</FormLabel>
				<Input
					placeholder='Нашата изработка'
					name='title'
					onChange={onChange}
				/>
			</FormControl>

			<FormControl isRequired>
				<FormLabel>Опис</FormLabel>
				<Textarea
					placeholder='Оваа изработка е од 100% рециклирани материјали...'
					name='desc'
					onChange={onChange}
				/>
			</FormControl>

			<FormControl>
				<label
					htmlFor='image'
					style={{ display: 'block', cursor: 'pointer', width: 'max-content' }}
				>
					<Button
						as='div'
						w='max-content'
						colorScheme='teal'
						variant='outline'
						leftIcon={<AttachmentIcon />}
					>
						Прикачете слика
					</Button>
				</label>
				<input
					type='file'
					id='image'
					name='image'
					accept='image/png, image/jpeg'
					style={{ display: 'none' }}
					onChange={() =>
						setImage((imageInput as any)?.current.files[0] ?? null)
					}
					ref={imageInput}
				/>
				{image && (image as any).name}
			</FormControl>

			<FormControl isRequired>
				<FormLabel>Лозинка</FormLabel>
				<Input
					placeholder='*******'
					name='password'
					type='password'
					onChange={onChange}
				/>
				<FormHelperText>
					Доколку немате лозинка, а сте одделенски раководител побарајте ја на
					"stojvlatko@gmail.com"
				</FormHelperText>
			</FormControl>

			<Button
				w='max-content'
				colorScheme='teal'
				size='lg'
				rightIcon={<ArrowRightIcon />}
				onClick={handleSubmit}
				isLoading={isSubmitting}
			>
				Сочувај
			</Button>
		</Stack>
	);
};

export { New };
