import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
	Box,
	Heading,
	Stack,
	Image,
	Text,
	Button,
	Badge,
	Tag,
	Flex,
	Divider,
	useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useAuthState } from 'react-firebase-hooks/auth';

import { db, firebase, auth } from '../api';
import Like from '../icons/Like';
import SignInModal from './SignInModal';

const Feed: React.FC = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [user, loading, error] = useAuthState(auth);

	const [feed, setFeed]: any[] = useState([]);
	const [liked, setLiked]: any[] = useState([]);

	const getLiked = async () => {
		const ref = db.collection('posts').orderBy('date', 'desc');

		const snapshot = await ref.get();

		const likedArr: any = [];

		const snapshotAwaits: (() => Promise<void>)[] = [];
		snapshot.forEach((snap) =>
			snapshotAwaits.push(async () => {
				const likerDocSnap = await db
					.collection('posts')
					.doc(snap.id)
					.collection('likers')
					.doc(user?.uid)
					.get();

				likedArr.push(likerDocSnap.exists);
			})
		);

		for (const asyncFunction of snapshotAwaits) {
			await asyncFunction();
		}

		setLiked(likedArr);
	};

	useEffect(() => user?.uid && (getLiked() as any), [user]);

	useEffect(() => {
		(async () => {
			const ref = db.collection('posts').orderBy('date', 'desc');

			const snapshot = await ref.get();

			const submissions: any = [];

			snapshot.forEach(async (snap) => {
				const subData = { id: snap.id, ...snap.data() };
				submissions.push(subData);
			});

			setFeed(submissions);
		})();
	}, []);

	const like = async (index: number) => {
		if (liked.length) {
			const subRef = db.collection('posts').doc(feed[index].id);

			const increment = liked[index] ? -1 : 1;

			(async () => {
				if (!liked[index])
					await subRef.collection('likers').doc(user?.uid).set({
						date: firebase.firestore.Timestamp.now(),
					});
				else await subRef.collection('likers').doc(user?.uid).delete();

				await subRef.update({
					likes: firebase.firestore.FieldValue.increment(increment),
				});
			})();

			const feedArr = feed.slice();
			feedArr[index].likes = feed[index].likes + increment;
			setFeed(feedArr);

			const likedArr = liked.slice();
			likedArr[index] = !likedArr[index];
			setLiked(likedArr);
		}
	};

	return (
		<>
			<SignInModal isOpen={isOpen} onClose={onClose} />

			<Box w='100%' d='inline'>
				<Button
					float='right'
					onClick={onOpen}
					mt={2}
					mr={2}
					disabled={user ? true : false}
				>
					{user ? user.phoneNumber : 'Логирајте се'}
				</Button>
			</Box>

			<Box
				maxW={{ base: '300px', sm: '400px' }}
				mx='auto'
				textAlign='center'
				p='1em 1em 3em'
			>
				<Heading mb={5} fontSize='2.1em'>
					Поднесени изработки
				</Heading>
				<Link to='/new'>
					<Button
						w='100%'
						mb={8}
						size='lg'
						colorScheme='teal'
						variant='outline'
						rightIcon={<AddIcon />}
					>
						Поднесете ново
					</Button>
				</Link>
				<Stack mx='auto' gridRowGap={5}>
					{feed &&
						feed.map((s: any, subI: number) => (
							<Box
								maxW='100%'
								borderWidth='1px'
								borderRadius='lg'
								overflow='hidden'
								key={subI}
							>
								<Image src={s.imageUrl} mx='auto' />
								<Divider />
								<Box py={5} px={3}>
									<Tag colorScheme='green'>{s.class} одд</Tag>
									<Heading
										as='h2'
										fontSize='3xl'
										fontWeight='semibold'
										lineHeight='1'
										mt={2}
									>
										{s.title}
									</Heading>
									<Text maxW='50ch' mb={2}>
										{s.desc}
									</Text>
									{s.students ? (
										<Flex
											gridGap={3}
											flexDirection='row'
											justifyContent='center'
										>
											{s.students.map((student: string, studentI: number) => (
												<Badge key={studentI}>{student}</Badge>
											))}
										</Flex>
									) : (
										<Badge>{s.student}</Badge>
									)}
								</Box>
								<Divider />
								<Flex
									_hover={{ cursor: 'pointer' }}
									onClick={() => like(subI)}
									alignItems='center'
									w='100%'
									justifyContent='center'
								>
									<Like
										py={3}
										boxSize='3.5em'
										color={liked[subI] ? 'messenger.500' : 'gray.400'}
									/>
									<Text
										fontWeight={liked[subI] ? 'bold' : 'normal'}
										fontSize='lg'
										lineHeight='1'
									>
										{s.likes || 0}
									</Text>
								</Flex>
							</Box>
						))}
				</Stack>
			</Box>
		</>
	);
};

export { Feed };
