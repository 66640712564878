import * as React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { ChakraProvider, theme } from '@chakra-ui/react';

import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Feed } from './pages/Feed';
import { New } from './pages/New';

export const App = () => (
	<ChakraProvider theme={theme}>
		<ColorModeSwitcher />
		<Router>
			<Route exact path='/' component={Feed} />
			<Route exact path='/new' component={New} />
		</Router>
	</ChakraProvider>
);
